@media screen and (max-width: 1160px) {
    div.price-box-cointainer {
        margin-top: 1.5em;
    }
}

div.price-box-cointainer {
    /* width: 350px; */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.pricing-box {
    border-radius: 2px;
}


.pricing-box .entry-image {
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
}

.pricing-box .entry-image img {
object-fit: scale-down;
}

.pricing-box .entry-inner .text {
    height: 300px;
    overflow: hidden;
}

.entry-inner {
    padding: 0 10px;
}


/* .pricing-box .entry-inner .upper {
    padding: 20px 0;
} */

/* .pricing-box .entry-inner .upper,
.pricing-box .entry-inner .upper h5 {
    color: #fff;
} */

.pricing-box .entry-inner>div.text p {
    font-size: 14px !important;
    line-height: 25px !important;
}



/* .pricing-box .entry-inner>div.text {
    font-size: 10px !important;
} */

/* .pricing-box .entry-inner .list h5 {
    color: #fff;
} */

.pricing-box .entry-inner .end {
    height: 80px;
    padding: 20px 0;
}

.pricing-box .entry-inner .end button {
    bottom: 15px;
    right: 15px;
}