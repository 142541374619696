.brand-text {
    color: #fb00ff !important;
}

.brand-text i {
    color: #fb00ff !important;
}

.brand-bg,
.sweet-alert button {
    background-color: #fb00ff !important;
}

.waves-effect.waves-brand {
    background-color: rgba(0, 188, 212, 0.66);
}

.p-overlay {
    background-color: rgba(0, 188, 212, 0.9);
}


.circle-loader .path {
    stroke: #fb00ff;
}


.side-nav li.current a,
.side-nav li a:hover {
    border-bottom-color: #fb00ff;
}

.static-menu li i:hover,
.static-menu li a:hover,
.submenu-ul li a:hover,
.home-title span,
.about-subtitle,
.person-about>a.btn-large,
.about-social ul li a i,
.skill-title,
.skill-count,
.single-widget ul li a,
.single-post-content a,
.share-post li a:hover,
.com-title a,
span.num {
    color: #fb00ff;
}

.singel-hr-inner,
.hr-wrap,
.hrc:after,
.thumb-slides-container.owl-theme .owl-controls .owl-page.active span,
.blog-inner .card-content a.right:hover,
.blog-inner .card-content a.right:focus,
.contact-form .submit-btn:hover,
.contact-form .submit-btn:focus,
a.tags,
.blog-inner .card-content a.right:active {
    background-color: #fb00ff;
}

.contact-form .materialize-textarea:focus,
.contact-form .input-box:focus {
    border-color: #fb00ff;
    box-shadow: 0px 1px 0px #fb00ff;
}


.single-post-content blockquote {
    border-left: 5px solid #fb00ff;
}



@-webkit-keyframes color {
    0% {
        stroke: #fb00ff;
    }

    20% {
        stroke: #fb00ff;
    }

    25% {
        stroke: #fb00ff;
    }

    45% {
        stroke: #fb00ff;
    }

    50% {
        stroke: #fb00ff;
    }

    70% {
        stroke: #fb00ff;
    }

    75% {
        stroke: #fb00ff;
    }

    95% {
        stroke: #fb00ff;
    }
}

@keyframes color {
    0% {
        stroke: #fb00ff;
    }

    20% {
        stroke: #fb00ff;
    }

    25% {
        stroke: #fb00ff;
    }

    45% {
        stroke: #fb00ff;
    }

    50% {
        stroke: #fb00ff;
    }

    70% {
        stroke: #fb00ff;
    }

    75% {
        stroke: #fb00ff;
    }

    95% {
        stroke: #fb00ff;
    }
}


/* Add your CSS below */




.menu-select [type="radio"]:checked+label:after {
    border-color: #fb00ff;
    background-color: #fb00ff;
}

button:focus {
    outline: none;
    background-color: #fb00ff;
}