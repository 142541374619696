/*
====================================================
* [Master Stylesheet]

  Theme Name : MaterialX
  Version    :  1.1
  Author     :  Bdinfosys
  Author URI :  http://themeforest.net/user/bdinfosys
====================================================

   TOC:
  =======
  1. Primary Styles
  2. Custom Utility Classes
  3. Navigation Menu Styles
  4. Home Section Styles
  5. About Me Section Styles
  6. Skills Section Styles
  7. Experience Section Styles
  8. Education Section Styles
  9. Portfolio Styles
  10. Testimonials Styles
  11. Blog Styles
  12. Fun Facts Styles
  13. Contact Styles
  14. Footer Styles
  15. Custom Styles
===================================================== */


/* --------------------------------- */
/* 1. Primary Styles
 ----------------------------------- */

html {
    font-size: 1em;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}


audio,
canvas,
img,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}


/*css reset*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/*css reset*/

img {
    max-width: 100%;
}

h1,
h2,
h3,
a {
    font-family: 'Roboto', sans-serif;
}

a {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

p {
    line-height: 30px;
    font-size: 18px;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    /* color: #fff; */
    font-weight: 400;
    background-color: #fafafa;
}

#preloader {
    background: none repeat scroll 0 0 #fff;
    display: block;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.loader {
    height: 50px;
    width: 50px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);

}

.circle-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 50px;
    left: 50%;
    position: absolute;
    top: 20%;
    width: 50px;
}

.circle-loader .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #3f88f8;
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

/* --------------------------------- */
/* 2. Custom Utility Classes
 ----------------------------------- */

.padd-tb-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.padd-tb-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padd-tb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padd-tb-73 {
    padding-top: 73px;
    padding-bottom: 73px;
}

.padd-tb-55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.row {
    margin-bottom: 0;
}


.bottom-50 {
    margin-bottom: 50px;
}

.btn-large {
    height: 50px;
    line-height: 50px;
    text-transform: inherit;
    margin-bottom: 0;
}

.container {
    position: relative;
}

.italic {
    font-style: italic;
}

.overflow-hidden {
    overflow: hidden;
}


.section-call-to-area {
    display: block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0px;
    min-height: 1px;
}

.section-call-to-area .row {
    position: relative;
}

.section-call-to-btn:hover,
.section-call-to-btn {
    position: absolute;
    bottom: -25px;
    background-color: #fff;
}

.section-call-to-btn i {
    color: #444444;
}

.section-call-to-btn.button-middle {
    left: 50%;
    margin-left: -27px;
}

.section-call-to-btn.button-right-1 {
    right: 47px;
}

.section-call-to-btn.button-right-2 {
    right: 16px;
}

/*slider inner div's */

#testimonialSlider .owl-item>div,
#teamSlider .owl-item>div,
#educationSlider .owl-item>div,
#experienceSlider .owl-item>div {
    width: 100%;
    margin: 10px 0;
}


/*reusable classes */

.title {
    font-size: 48px;
    line-height: 1.1;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.regular-text {
    font-size: 16px;
    font-weight: 400;
}

.btn-large {
    text-transform: uppercase;
}

section.cyan {
    color: #fff;
}

.main-section {
    overflow: hidden;
}

section.brand-bg .title {
    color: #fff;
}

.inline-menu li a {
    font-size: 13px;
    font-weight: 500;
}


.btn-floating.btn-up {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn-floating.btn-up,
.btn-floating.btn-hidden {
    -webkit-transition: all 1.3s ease;
    transition: all 1.3s ease;
}


.btn-hidden {
    -webkit-transform: translateX(1000px) !important;
    -ms-transform: translateX(1000px) !important;
    transform: translateX(1000px) !important;
}

.btn-floating.btn-show {
    -webkit-transition: all .3s ease !important;
    transition: all .3s ease !important;
}

.btn-floating.btn-large i {
    line-height: 59.5px;
}

/* --------------------------------- */
/* 3. Navigation Menu Styles
 ----------------------------------- */

header.nav {
    box-shadow: 0 1px 6px rgba(0, 0, 0, .35);
    width: 100%;
    top: 0px;
    position: fixed;
    z-index: 999;
    -webkit-transition: top .5s;
    transition: top .5s;
}

header.nav.hide-menu {
    top: -90px;
}

.primary-nav {
    height: inherit;
    line-height: inherit;
    background-color: transparent !important;
    box-shadow: none;
    padding: 0;
}

.primary-nav .brand-logo {
    position: static;
    font-size: inherit;
}

.primary-nav .brand-logo img {
    height: 32px;
    margin: 25px 5px 0;
}

.mobile-profile {
    display: none;
}

.side-nav li {
    margin-left: 40px;
}

.side-nav li,
.side-nav li:hover {
    background-color: transparent;
}

.side-nav li:first-child {
    margin-left: 0;
}

.side-nav li a {
    color: #727272;
    padding: 25px 0;
    border-bottom: 6px solid transparent;
    text-transform: uppercase;
    background-color: transparent;
    line-height: 24px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.side-nav li.current a,
.side-nav li a:hover {
    background-color: transparent;
}

.side-nav li i {
    display: none;
}

.static-menu {
    margin-left: 40px;
}

.static-menu li:hover {
    background-color: transparent;
}

.static-menu li a {
    line-height: 27px;
    padding: 22px 0;
    font-size: 25px;
    color: #727272;
    border-bottom: 6px solid transparent;
    background-color: transparent;
}

.static-menu li i:hover,
.static-menu li a:hover {
    background-color: transparent;
}

.static-menu li a i {
    line-height: inherit;
    height: inherit;
    font-size: inherit;
    float: none !important;
    margin: 0;
    vertical-align: middle;
}

.static-menu li:last-child {
    margin-left: 12px;
}

.static-menu {}

.static-menu form {
    width: 300px;
    position: relative;
    height: 80px;
    max-width: 100%;
    padding-left: 35px;
}

#initSearchIcon {
    cursor: pointer;
}

.static-menu .search {
    width: 100%;
    margin-bottom: 0;
    color: #212121;
    font-size: 15px;
    margin: 0;
    padding: 13px 0px;
}

.static-menu .search,
.static-menu .search:hover,
.static-menu .search:focus,
.static-menu .search:active {
    border: none;
    box-shadow: none;
}


.static-menu form button {
    line-height: inherit;
    height: inherit;
    border: none;
    padding: 0;
    font-size: 14px;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
}

.static-menu form i {
    height: inherit;
    line-height: inherit;
    font-size: 26px;
    color: #727272;
}

.submenu-ul {
    min-width: 170px;
    overflow: visible !important;
    max-height: 100%;
    height: auto !important;
    padding: 20px;
}

.submenu-ul li {
    color: #cccbcb;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 21px;
}

.submenu-ul li:last-child {
    margin-bottom: 0;
}

.submenu-ul li:hover {
    background: transparent;
}

.submenu-ul li a {
    padding: 0;
    color: #727272;
    background-color: transparent;
    display: block;
    line-height: 1.1;
}

.submenu-ul li a:hover {
    background-color: transparent;
}

/* --------------------------------- */
/* 4. Home Section Styles
 ----------------------------------- */

 /* For mobile devices */
#home {
    object-fit: scale-down;
    background: url('../images/home_background_mobile.jpg') no-repeat fixed;
}

/* For screens larger then 400 */
@media only screen and (min-device-width: 400px) {
    #home {
        background: url('../images/home_background.jpg') no-repeat fixed center center / cover;
    }
  }

#home .sec-overlay {
    background: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100%;
}

.home-inner {
    display: table;
    height: calc(100vh - 50px);
    padding-top: 80px;
    width: 100%;
}

.home-content {
    color: #fff;
    display: table-cell;
    vertical-align: middle;
}

.home-title {
    font-size: 72px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.home-subtitle {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 38px;
    text-transform: uppercase;
}

.home-content>a.btn-large i {
    font-size: 20px;
    margin-right: 10px;
}

.home-content>a.btn-large {
    height: 45px;
    line-height: 45px;
}


/* --------------------------------- */
/* 5. About Me Section Styles
 ----------------------------------- */

.about-inner {
    margin: 60px 0;
}

.person-about .title {
    margin-bottom: 15px;
}

.about-subtitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.person-about .subtitle {
    font-weight: 100;
    font-size: 35px;
    margin-bottom: 20px;
}

.person-img img {
    border: 8px solid #fff;

}

.person-about p {
    color: #727272;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 35px;
}

.person-info h5 {
    border-bottom: 1px solid #eeeeee;
    color: #727272;
    font-size: 14px;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.person-info h5 span {
    display: inline-block;
    min-width: 70px;
}

.person-about>a.btn-large {
    background-color: #fff;
}

.person-about>a.btn-large {
    font-size: 14px;
}

.person-about>a.btn-large,
.home-content>a.btn-large {
    padding-right: 15px;
    padding-left: 15px;
}

.person-about>a.btn-large i {
    font-size: 26px;
    margin-right: 8px;
}

.about-social {
    margin: 25px 0 0;
}

.about-social ul li {
    display: inline-block;
    margin-right: 8px;
}

.about-social ul li a i {
    font-size: 14px;
}


/* --------------------------------- */
/* 6. Skills Section Styles
 ----------------------------------- */

#saas {
    background: url('../images/home_saas.jpg') no-repeat scroll center center / cover;
}

#saas .sec-overlay {
    background: rgba(255, 255, 255, .92);
    height: 100%;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 100px;
}

.skill-inner {
    margin-top: 70px;
}

.skill-left {
    margin-top: 136px;
}

.skill-left p {
    line-height: 30px;
}

.skill-graph {
    margin-bottom: 0px;
}

.skill-graph .single-skill {
    /*float: left;*/
    height: 490px;
    width: 60px;
    position: relative;
}

.after-li {
    position: absolute;
    width: 100%;
    bottom: 10px;
    height: 350px;
}

.singel-hr {
    position: relative;
    height: 100%;
    padding-bottom: 50px;
}

.skill-title,
.skill-count {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 400;
    position: absolute;
}

.skill-title {
    font-size: 13px;
    line-height: 15px;
}

.skill-count {
    font-size: 15px;
}

.skill-title {
    top: -100px;
}

.skill-count {
    bottom: 0;
}

.singel-hr-inner {
    position: absolute;
    width: 100%;
    bottom: 33px;
    border-radius: 2px;
    height: 0px;
    -webkit-transition: all 1.2s;
    transition: all 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.skill-visiable {
    position: relative;
}

.hr-wrap {
    height: 73px;
    width: 1px;
    position: absolute;
    top: -73px;
    left: 50%;
}

.hrc:after {
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: -3px;
}

.root-sec {
    position: relative;
}

.btn-wrapp {
    position: absolute;
    right: 1.4%;
    top: 55.8%;
}

.btn-wrapp a {
    display: block;
    margin-bottom: 20px;
}

.btn-wrapp a:last-child {
    margin-bottom: 0;
}

.skill-wrap .btn-wrapp {
    bottom: 7.5%;
    top: auto;
    right: 4%;
}

/* --------------------------------- */
/* 7. Experience Section Styles
 ----------------------------------- */

.experience-wrap {
    padding: 110px 0;
}

.section-head {
    margin-bottom: 65px;
}

.card {
    margin: 0;
}

.rev-title-wrap p,
.card .card-content p {
    line-height: inherit;
    color: #727272;
    font-size: 14px;
    margin-top: 10px;
}

.single-post {
    margin-bottom: 30px;
}

.post-title-link {
    display: table;
    margin: 0px 0px 15px !important;
}

.single-card-box .card .card-title i {
    color: #727272;
}

.experience-inner .card .card-image img {
    width: 100%;
    height: auto;
}

.card-title-top {
    color: #727272;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    line-height: 1.7;
}

.valign-wrapper.card-img-wrap {
    border-bottom: 1px solid #eee;
    min-height: 140px;
}

.card .card-content {
    padding: 9px 20px;
}

.single-card-box .card .card-title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 10px;
}

.card a {
    font-size: 14px;
    text-transform: inherit;
}

.card i {
    font-size: 24px;
}

.card .card-reveal {
    padding: 0;
}

.rev-title-wrap {
    padding: 13px 19px;
    border-bottom: 1px solid #eee;
}

.rev-content {
    padding: 20px;
    color: #727272;
    font-size: 14px;
    line-height: 25px;
}


/* --------------------------------- */
/* 8. Education Section Styles
 ----------------------------------- */

.education-text {
    color: #727272;
}

.education-inner .card .card-image img {
    max-width: 100%;
}

/* --------------------------------- */
/* 9. Portfolio Styles
 ----------------------------------- */

.portfolio-wrap {
    padding-bottom: 120px;
}

.portfolio-top {
    padding-bottom: 3px;
    margin-bottom: 50px;
}

.portfolio-top .title {
    color: #fff;
    margin-bottom: 60px;
}

.portfolio-category {}

.portfolio-category li {
    float: left;
    margin-right: 25px;
}

.portfolio-category li a {
    display: block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 10px;
    border-style: solid;
    border-color: transparent;
    border-width: 0 0 3px 0;
    line-height: 20px;
    padding-right: 1px;
    padding-left: 1px;
}

.portfolio-category li.active a,
.portfolio-category li a:hover {
    border-color: #fff;
}

.protfolio-item {
    margin-bottom: 50px;
}

.protfolio-item li {
    float: left;
    width: 33%;
    margin-right: .33%;
    margin-bottom: .33%;
}

.protfolio-item li a {
    display: block;
    background-color: inherit;
}

.protfolio-image {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.protfolio-image img {
    display: block;
}

.protfolio-content {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
    vertical-align: middle;
    text-align: center;
    z-index: 5;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(.4, 0, .2, 1), opacity 0.25s;
    transition: transform 0.25s cubic-bezier(.4, 0, .2, 1), opacity 0.25s;
}

.p-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    filter: alpha(opacity=0);
    opacity: 0;
}

.protfolio-content:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    min-width: 1px;
    margin-left: -6px;
}

.protfolio-content__inner {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.protfolio-content .p-title {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
}

.protfolio-image:hover .p-overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1.6);
    -ms-transform: scale(1.6);
    transform: scale(1.6);
}

.protfolio-image:hover .protfolio-content {
    opacity: 0.9;
    filter: alpha(opacity=90);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}


.load-more:hover,
.load-more {
    background-color: #fff;
    padding-right: 20px;
    padding-left: 20px;
    color: #727272;
    font-size: 14px;
}

.portfolio-wrap .modal {
    padding: 0;
    max-height: 85%;
    width: 550px;
}

.modal .model-img {
    padding: 5px 5px 0 5px;
    margin-bottom: 25px;
}

.modal .model-img img {
    width: 100%;
    height: auto;
}

.modal .title {
    font-size: 25px;
    color: #444444;
    line-height: 1.2;
    margin-bottom: 15px;
}

.modal .modal-content {
    padding: 0 20px 30px;
    border-bottom: 1px solid #eeeeee;


}

.modal .modal-content p {
    color: #727272;
    font-size: 14px;
    line-height: 30px;
}

.modal .modal-footer {
    padding: 10px 5px;
}

.modal .modal-footer .btn-flat {
    padding: 0 15px;
    font-weight: 500;
    font-size: 14px;
}

/* --------------------------------- */
/* 10. Testimonials Styles
 ----------------------------------- */


.testimonial-inner {}

.testimonial-inner .card .card-image img {
    width: 100px;
    height: auto;
    margin-bottom: 25px;
}

.testimonial-inner .card-img-wrap {
    padding: 20px 20px 0 20px;
    position: relative;
}

.saying-about {
    color: #727272;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 15px;
}

.testimonial-inner .card-content a {
    color: #777777;
    font-size: 13px;
    font-weight: 400;
}

.testimonial-inner .card-img-wrap:after {
    content: "\e786";
    font-family: "Material-Design-Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    color: #e5e5e5;
    z-index: 77;
    top: 0;
    right: 0;
    font-size: 70px;
}

.testimonial-inner .btn-wrapp {
    top: 60.5%;
}

/* --------------------------------- */
/* 11. Blog Styles
 ----------------------------------- */

.post-body {
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
    border-bottom: none;
}

.blog-inner .card-img-wrap {
    padding: 5px;
    padding-bottom: 0;
}

.post-title {
    font-size: 18px;
}

.post-title-link:hover {
    color: #444 !important;
}

.post-content {
    line-height: 25px;
    font-size: 14px;
    color: #777777;
}


.blog-inner .card-content {
    border-top: 1px solid #eeeeee;
}

.grey .regular-text,
.white .regular-text {
    color: #727272;
}

.blog-post-thumb {
    position: relative;
}

.blog-post-thumb.videoPost {
    padding-bottom: 56.25%;
}

.blog-post-thumb.videoPost>* {
    width: 100% !important;
    border: none !important;
    height: 100% !important;
    position: absolute;
}


.jwlogo {
    display: none !important;
}


.thumb-slides-container img {
    width: 100%;
}

.thumb-slides-container.owl-theme .owl-controls {
    position: absolute;
    bottom: 10px;
    right: 10px;
}


.thumb-slides-container.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px !important;
    opacity: 1;
    filter: alpha(opacity=100);
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.blog-inner .card-element-wrapper {
    margin-bottom: 10px;
}


.blog-inner .card-content a {
    line-height: 25px;
    margin-right: 0;
}

.blog-inner .card-content a.left {
    color: #cfcfcf;
    font-size: 20px;
}

.blog-inner .card-content a.left i {
    font-size: 20px;
    vertical-align: middle;
}

.blog-inner .card-content a.left .numb {
    font-size: 13px;
    color: #727272;
    vertical-align: middle;
    margin-left: 5px;
    font-weight: 500;
}

.blog-inner .card-content a.left:hover,
.blog-inner .card-content a.left.active {
    color: #ff5d5d;
}

.blog-inner .card-content a.right {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 1px;
    padding: 4px 10px;
    padding-right: 0px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.blog-inner .card-content a.right:hover,
.blog-inner .card-content a.right:focus,
.blog-inner .card-content a.right:active {
    padding-right: 10px;
    color: #fff !important;
}

/* --------------------------------- */
/* 12. Fun Facts Styles
 ----------------------------------- */

#funfacts {
    /* background: url('../../images/fun-facts-bg.jpg') no-repeat scroll center center / cover; */
}

#funfacts .sec-inner {
    background: rgba(255, 255, 255, .92);
    height: 100%;
    width: 100%;
}

.funfact-box .card-panel {
    height: 280px;
    margin: 15px 0;
}

span.num {
    font-size: 50px;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 20px;
}

.funfact-box span {
    display: block;
}

.context {
    color: #727272;
    font-size: 13px;
    text-transform: uppercase;
}

.funfact-box .card-panel {
    position: relative;
}

.funfact-box-inner i {
    color: #dbdbdb;
    font-size: 60px;
    margin-bottom: 13px;
    display: block;

}

.feature-box-outer {}

.feature-box-outer:before {
    display: inline-block;
    content: "";
    height: 100%;
    width: 1px;
    margin-left: -6px;
    vertical-align: middle;
}


.feature-box-outer {
    width: 100%;
    height: 100%;
}


.funfact-box-inner {
    display: inline-block;
    vertical-align: middle;
}


/* --------------------------------- */
/* 13. Contact Styles
 ----------------------------------- */

#map {
    width: 100%;
    height: 474px;
    border-radius: 3px;
}

#map * {
    color: #727272;
    font-size: 13px;
}

#map img {
    max-width: inherit;
}

.contact-info {
    display: block;
    padding: 8px 0;
}

.contact-info li {
    float: left;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #fff;
}

.contact-info li a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 14px;
    display: block;
}

.contact-info li:last-child {
    border-right: none;
    box-shadow: none;
}


.contact-form .input-field {
    margin-top: 0;
    margin-bottom: 20px;
}

.contact-form .card-panel {
    margin: 0;
}

.contact-form .input-label {
    color: #bbbbbb;
    left: 0;
    font-size: 15px;
}

.contact-form .input-label.active {
    color: #727272 !important
}

.contact-form .materialize-textarea,
.contact-form .input-box {
    margin-bottom: 0;
    border-bottom: 1px solid #ececec;
    box-shadow: 0px 1px 0px #fff;
    color: #444444;
    font-size: 15px;
    height: 50px;
}

.contact-form .materialize-textarea {
    padding: 16px 0;
    margin: 0;
    height: 132px !important;
    overflow: hidden;
    overflow-y: auto;
}

.input-field.textarea-input label {
    bottom: auto;
    top: 15px;
}

.textarea-input label.active {
    bottom: auto;
    top: 15px;
}

.contact-form .submit-btn {
    margin-bottom: 0;
    padding: 0 15px;
    font-size: 13px;
    font-weight: 500;
    padding-left: 0;
}

.contact-form .submit-wrap {
    margin-bottom: 0px;
}

.contact-form .submit-btn:hover,
.contact-form .submit-btn:focus {
    padding-left: 15px;
    color: #fff !important
}


.form-loader-area {
    margin-top: 9px;
    display: none;
}

#message-success {
    display: none;
    position: absolute;
    right: 0;
}

#message-success p {
    font-size: 14px;
    color: #4CAF50;
}

.sweet-alert button {
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}


/* --------------------------------- */
/* 14. Footer Styles
 ----------------------------------- */


.social-icons {
    float: left;
}

.copyright {
    float: right;
}

.footer-wrap {
    padding: 35px 0;
}

.social-icons li a {
    color: #727272;
    font-size: 20px;
}

.social-icons li a.facebook:hover {
    color: #3b5998;
}

.social-icons li a.linkedin:hover {
    color: #0976b4;
}

.social-icons li a.twitter:hover {
    color: #55acee;
}

.social-icons li a.google-plus:hover {
    color: #dd4b39;
}

.social-icons li a.dribbble:hover {
    color: #ea4c89;
}

.social-icons li a.behance:hover {
    color: #1769ff;
}

.material-tooltip {
    background-color: #303030;
    padding: 5px;
    line-height: inherit;
    font-size: 13px;
    min-height: inherit;
}

.material-tooltip span {
    font-size: 13px;
}

.social-icons li {
    margin-right: 20px;
    display: inline-block;
}

.copyright p {
    color: #c4c4c4;
    font-size: 12px;
    line-height: 20px;
}


/* --------------------------------- */
/* 15. Custom Styles
 ----------------------------------- */

/*customizing materialize.css*/

.container {
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1170px;
    width: 90%;
}

@media only screen and (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media only screen and (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container .row {
    margin-right: -15px;
    margin-left: -15px;
}

.row .col {
    padding-right: 15px;
    padding-left: 15px;
}



.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

@media only screen and (min-width: 35em) {
    /* Style adjustments for viewports that meet the condition */
}

@media print {
    * {
        background: transparent !important;
        color: #000 !important;
        /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " ("attr(href) ")";
    }

    abbr[title]:after {
        content: " ("attr(title) ")";
    }

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
        /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}



@-webkit-keyframes fadeInUpSmall {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUpSmall {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUpSmall {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall
}

@-webkit-keyframes fadeOutUpSmall {
    0% {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    100% {
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
}

@keyframes fadeOutUpSmall {
    0% {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    100% {
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
}

.fadeOutUpSmall {
    -webkit-animation-name: fadeOutUpSmall;
    animation-name: fadeOutUpSmall;
}





.circular {
    width: 40px;
    height: 40px;
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
}


.circular.size-20 {
    width: 20px;
    height: 20px;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #000;
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

.spinner-container {
    /* position: absolute; */
    margin-top: 80px;
    text-align: center;
    width: calc(100% - 35px);
    height: calc(100% - 20px);
    vertical-align: middle;
}

.spinner-container>span {
    font-size: 45px;
    color: #afb6bd;
}

.text-truncate {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}